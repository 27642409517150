/* Copyright (C) 2018 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
function SandboxController(userService) {
    this.pins = [];

    var props = this.props = {
        user: userService.getUser(),
        tags: [
            '2015',
            'ECMA',
            'JavaScript',
            'Fall'
        ],
        onUpdate(tags) {
            console.log('tags before', props.tags);
            props.tags = tags;
            console.log('tags after', props.tags);
        },
    };

    this.createComment = pin => {
        this.pins.push(pin);
    };
}

app.controller('SandboxController', SandboxController);