/* Copyright (C) 2023 PageProof Holdings Limited - All Rights Reserved.
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */
const SPRITE_SHEET_COLS = 10;

class ProofPagesController {
    show = false; // bool
    pages = []; // []PProofPage
    currentPage = 0;
    loadingPage = 0;
    whenSwitchPage = null;
    whenLoadPage = null;
    urls = [];

    constructor($q, $timeout, bugsnag) {
        this.$$ = {
            $q,
            $timeout,
            bugsnag,
        };
    }

    getPageGridViewProps() {
        return {
          loadingPage: this.loadingPage,
          currentPage: this.currentPage,
          pages: this.pages,
          onPageSelect: (selectedPage) => this.switchPage(selectedPage),
          fileId: this.fileId,
          show: this.show,
          pagesMetadata: this.pagesMetadata,
        }
    }

    switchPage(page) {
        if (this.loadingPage) return;

        if (this.currentPage === page.pageNumber) {
          this.whenTogglePages();
        } else {
          this.$$.$q.when(this.whenSwitchPage({
              pageNumber: page.pageNumber,
              page,
          }))
          .then((allow) => allow && this.requestLoadPage(page));
        }
    }

    requestLoadPage(page) {
        if (this.loadingPage) return;
        this.loadingPage = page.pageNumber;

        return (
            this.$$.$q.when(this.whenLoadPage({
                pageNumber: page.pageNumber,
                page: page,
            }))
            .then(() => this.loadingPage = 0)
        );
    }

    destroy() {
        let url;
        while ((url = this.urls.shift())) {
            try {
                URL.revokeObjectURL(url);
            } catch(err) {
                this.$$.bugsnag.notifyException(err);
            }
        }
    }
}

function ProofPagesDirective(directiveHelper) {
    return {
        require: ['proofPages'],
        controller: ProofPagesController,
        controllerAs: 'proofPagesCtrl',
        template: `
            <react-component
                name="PageGridView"
                props="proofPagesCtrl.getPageGridViewProps()"
            >
            </react-component>
        `,
        scope: true,

        link(scope, element, attr, [proofPagesCtrl]) {
            directiveHelper.oneWayBinding(scope, attr, 'show', proofPagesCtrl, 'show');
            directiveHelper.oneWayBinding(scope, attr, 'pages', proofPagesCtrl, 'pages');
            directiveHelper.oneWayBinding(scope, attr, 'currentPage', proofPagesCtrl, 'currentPage');
            directiveHelper.oneWayBinding(scope, attr, 'fileId', proofPagesCtrl, 'fileId');
            directiveHelper.oneWayBinding(scope, attr, 'pagesMetadata', proofPagesCtrl, 'pagesMetadata');
            directiveHelper.callbackBinding(scope, attr, 'whenSwitchPage', proofPagesCtrl, 'whenSwitchPage');
            directiveHelper.callbackBinding(scope, attr, 'whenLoadPage', proofPagesCtrl, 'whenLoadPage');
            directiveHelper.callbackBinding(scope, attr, 'whenTogglePages', proofPagesCtrl, 'whenTogglePages');
            directiveHelper.controllerBinding(scope, attr, 'bind', proofPagesCtrl);

            scope.$on('$destroy', () => proofPagesCtrl.destroy());
        },
    };
}

app.directive('proofPages', ProofPagesDirective);
